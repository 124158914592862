// extracted by mini-css-extract-plugin
export var Button = "blog-post-module--Button--46b14";
export var Content = "blog-post-module--Content--021ee";
export var Date = "blog-post-module--Date--899cf";
export var Footer = "blog-post-module--Footer--36f06";
export var Header = "blog-post-module--Header--1a4fc";
export var Hero = "blog-post-module--Hero--f78c3";
export var HeroImage = "blog-post-module--HeroImage--66182";
export var Image = "blog-post-module--Image--4cad2";
export var Name = "blog-post-module--Name--990c7";
export var PostContainer = "blog-post-module--PostContainer--740b6";
export var PostContent = "blog-post-module--PostContent--13e26";
export var Role = "blog-post-module--Role--297e4";
export var Social = "blog-post-module--Social--f798e";
export var Tag = "blog-post-module--Tag--48c00";
export var Title = "blog-post-module--Title--33123";