import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import React from 'react';
import { Seo } from '../components/Seo';
import { OutboundLink } from '../components/UI/OutboundLink/OutboundLink';
import Layout from '../layouts/layout';
import { getCurrentLocale, getTagToDisplay, URLS } from '../shared/utils';
import * as classes from './blog-post.module.scss';

const BlogPost = ({ data, location }) => {
    const post = data.ghostPost;

    const langTag = find(post.tags, str => str.name.indexOf('lang:') === 0)?.name || 'lang:en';
    const postLang = langTag.split(':')[ 1 ];
    const lang = getCurrentLocale(location.pathname);

    // Function to replace http with https in image URLs
    const fixLinks = (content) => {
        return content
            // Replace http:// in src, href, and srcset attributes
            .replace(
                /(src="|href="|srcset=")http:\/\//g,
                '$1https://'
            )
            // Replace http:// within srcset values (which can contain multiple URLs)
            .replace(
                /(\s)http:\/\//g,
                '$1https://'
            )
            // Replace cms.aidacloud.com
            .replace(
                /cms\.(aidacloud\.com)/g,
                `www.$1`
            );
    };

    if (!!lang && lang !== postLang) {
        // Redirect to /blog
        if (typeof window !== 'undefined') {
            navigate(`/${lang === 'en' ? '' : `${lang}/`}blog`);
        }
        return null; // Return null while redirecting
    }

    const tagsToDisplay = getTagToDisplay(post.tags, false);
    console.log('post', post, tagsToDisplay);
    const author = post.authors[ 0 ];

    return <Layout location={location}>
        <article className="container p-0">
            <div className={classes.PostContainer}>
                <div className={classes.PostContent}>
                    <div className={classes.Header}>
                        {tagsToDisplay.map(tag => <div key={`tag-${tag.id}`} className={classes.Tag}>{tag.name}</div>)}
                        <div className={classes.Date}>{new Date(post.published_at).toLocaleDateString()}</div>
                    </div>
                    <h1 className={classes.Title}>{post.title}</h1>
                    <div className={classes.Hero}>
                        {post.feature_image && (
                            <figure>
                                <GatsbyImage
                                    image={post.remote_image?.childImageSharp.gatsbyImageData}
                                    alt={post.feature_image_alt || post.title}
                                    className={classes.HeroImage}
                                />
                            </figure>
                        )}
                    </div>
                    <div className={classes.Content} dangerouslySetInnerHTML={{ __html: fixLinks(post.html) }} />
                    <div className={classes.Footer}>
                        <div className={classes.Image}>
                            {author.remote_image &&
                                <GatsbyImage image={author.remote_image.childImageSharp.gatsbyImageData}
                                             alt={`${author.name} - ${author.bio}`} width={80} height={80} />}
                        </div>
                        <div>
                            <div className={classes.Name}>{author.name}</div>
                            <div className={classes.Role}>{author.bio}</div>
                        </div>
                        <div className={classes.Social}>
                            <OutboundLink href={URLS.FACEBOOK} target="_blank" rel="noopener" aria-label="Facebook">
                                <FontAwesomeIcon icon={[ 'fab', 'facebook' ]} />
                            </OutboundLink>
                            <OutboundLink href={URLS.INSTAGRAM} target="_blank" rel="noopener" aria-label="Instagram">
                                <FontAwesomeIcon icon={[ 'fab', 'instagram' ]} />
                            </OutboundLink>
                            <OutboundLink href={URLS.TWITTER} target="_blank" rel="noopener" aria-label="Twitter">
                                <FontAwesomeIcon icon={[ 'fab', 'twitter' ]} />
                            </OutboundLink>
                            <OutboundLink href={URLS.LINKEDIN} target="_blank" rel="noopener" aria-label="Linkedin">
                                <FontAwesomeIcon icon={[ 'fab', 'linkedin' ]} />
                            </OutboundLink>
                            <OutboundLink href={URLS.YOUTUBE} target="_blank" rel="noopener" aria-label="Youtube">
                                <FontAwesomeIcon icon={[ 'fab', 'youtube' ]} />
                            </OutboundLink>
                        </div>
                    </div>
                    <div className={classes.Button}>
                        <Link to="/blog"
                              className="btn btn-primary"
                              icon={[ 'far', 'arrow-left' ]}
                        >
                            <FontAwesomeIcon icon={[ 'far', 'arrow-left' ]} />&nbsp;
                            <Trans>Back</Trans>
                        </Link>
                    </div>
                </div>
            </div>
        </article>
    </Layout>;
};

export const query = graphql`
  query($slug: String!, $language: String!) {
    ghostPost(slug: { eq: $slug }) {
      title
      html
      slug
      excerpt
      meta_description
      authors {
        slug
        name
        remote_image {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, width: 80, height: 80, layout: FIXED, transformOptions: {fit: COVER})
          }
        }
        bio
      }
      feature_image
      feature_image_alt
      feature_image_caption
      remote_image {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR, 
            width: 850,
            height: 478,
            layout: CONSTRAINED, 
            transformOptions: {
              fit: COVER,
              cropFocus: CENTER
            }
          )
        }
      }
      published_at
      tags {
        id
        name
      }
    }
     locales: allLocale(
        filter: {ns: {in: ["common", "blog"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default BlogPost;

export const Head = ({ data }) => {
    const post = data.ghostPost;
    const edge = find(data.locales.edges, [ 'node.ns', 'blog' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = post.title || (!!obj ? obj.title : 'Not found');

    // Prioritize meta_description, fall back to excerpt, then to obj.description
    const description = post.meta_description || post.excerpt || (!!obj ? obj.description : null);

    return <Seo
        title={title}
        description={description + ' - Blog'}
    />;
};
